import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClientModule } from "@angular/common/http";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ToastrModule, ToastrService } from "ngx-toastr"; //---npm install ngx-toastr@10.1.0
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgxSlickJsModule } from "ngx-slickjs";
import { DatePipe } from "@angular/common";
// import { TestComponent } from './pages/career/test/test.component';
// import { CareerComponent } from './pages/career/career.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // TestComponent,
    // CareerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSlickJsModule.forRoot({
      links: {
        jquery: "https://code.jquery.com/jquery-3.4.0.min.js",
        slickJs:
          "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js",
        slickCss:
          "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css",
        slickThemeCss:
          "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css",
      },
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({ positionClass: "toast-top-center" }), // ToastrModule added for center
  ],
  providers: [
    DatePipe,
    { provide: ToastrService, useClass: ToastrService }, //this is used for toaster
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
