import { Component, OnDestroy, OnInit } from "@angular/core";
import { Slick } from "ngx-slickjs";
import { AllapiService } from "../allapi.service";
import { version } from '../../../package.json';
import { Subscription } from "rxjs";
declare var $: any;
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit, OnDestroy {
  currentYear: number = new Date().getFullYear();

  imgUrl: any;
  imageObjectData: any = [];

  youTube: any;
  youTubeViews: any;
  instaGram: any;
  instaGramViews: any;
  twiTter: any;
  twiTterViews: any;
  faceBook: any;
  faceBookViews: any;

  AllSocialData: any = [];

  releaseVersion: string;
  sub1: Subscription | undefined = undefined;
  sub2: Subscription | undefined = undefined;
  counterTime: any = undefined;

  constructor(private _server: AllapiService) {
    this.releaseVersion = version;
    console.log('this.releaseVersion :>> ', this.releaseVersion);
    this.imgUrl = this._server.partnerSlider;
    this.fetchSocialDetails();
  }

  ngOnInit() {
    this.counterTime = setTimeout(() => {
      this.fetchPartner();
      // this.counter();
      this.anothercounter();
    }, 1600);
  }
  config: Slick.Config = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 500,
  };
  fetchPartner() {
    this.sub1 = this._server.getApi("G9").subscribe((res) => {
      this.imageObjectData = res.response;
    });
  }
  // ---------------Social
  fetchSocialDetails() {
    this.sub2 = this._server.getApi("M4").subscribe((res) => {
      if (res.responseCode == 200) {
        this.AllSocialData = res.response;
        this.youTube = this.AllSocialData[0].youtube_number;
        this.youTubeViews = this.AllSocialData[0].youtube_views;
        this.instaGram = this.AllSocialData[0].insta_number;
        this.instaGramViews = this.AllSocialData[0].insta_views;
        this.twiTter = this.AllSocialData[0].twitter_number;
        this.twiTterViews = this.AllSocialData[0].twitter_views;
        this.faceBook = this.AllSocialData[0].fb_number;
        this.faceBookViews = this.AllSocialData[0].fb_views;
      }
    });
  }
  counter() {
    $(".count").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 6000,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  }
  anothercounter() {
    function animateValue(obj, start = 5000, end = null, duration = 20000) {
      if (obj) {
        var textStarting = obj.innerHTML;
        end = end || parseInt(textStarting.replace(/\D+/g, ""));
        var range = end - start;
        var minTimer = 50;
        var stepTime = Math.abs(Math.floor(duration / range));
        stepTime = Math.max(stepTime, minTimer);
        var startTime = new Date().getTime();
        var endTime = startTime + duration;
        var timer;
        function run() {
          var now = new Date().getTime();
          var remaining = Math.max((endTime - now) / duration, 0);
          var value = Math.round(end - remaining * range);
          obj.innerHTML = textStarting.replace(/([^0-9.])/g, value);
          if (value == end) {
            clearInterval(timer);
          }
        }
        timer = setInterval(run, stepTime);
        run();
      }
    }
    animateValue(document.getElementById("value1"));
    animateValue(document.getElementById("value2"));
    animateValue(document.getElementById("value3"));
    animateValue(document.getElementById("value4"));
  }
  redirectTo(action: string) {
    if (action.includes('securian')) {
      this._server.redirectTo('https://securiancanada.ca/');
    } else if (action.includes('patronus')) {
      this._server.redirectTo('https://www.patronus.live/');
    } else {
      //---no url
    }
  }
  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.counterTime) {
      clearTimeout(this.counterTime);
    }
    this.imageObjectData = [];
    this.AllSocialData = [];
  }
}
