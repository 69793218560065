import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GT20Canada';
  showMenu: boolean = false;

  // right click disable https://stackblitz.com/edit/angular-ryh715?file=src%2Fapp%2Fno-right-click.directive.ts
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  constructor(public router: Router, private meta: Meta) {
    router.events.forEach((response) => {
      if (response instanceof NavigationStart) {
        // console.log(response.url)
        if (response.url === '/pagenotfound') {
          this.showMenu = false;
        }
        else {
          this.showMenu = true;
        }
        this.meta.addTag({name: 'robots', content:'index, follow'});
        this.meta.updateTag({
          name:'description', content:'CANADA has a long Cricket history, dating back to the 1800s when it participated in the first-ever official International Cricket match.'
        });
      }
    });
  }
}
