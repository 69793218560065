import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AllapiService {
  baseurl: any;
  // ==========S3 Img Url==============
  dashboardSlider: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Dashboard_Slider/";
  partnerSlider: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Dashboard_Partner/";
  mixed: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Mixed/"
  mediaNews: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Media_News/"
  mediaPicture: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Media_Picture/"
  playerImage: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/p_image/";
  passportScan: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/passport_scanned/";
  nocCopy: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/noc_copy/";
  visaCopy: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/visa_copy/";
  // ================S3 Url===============
  constructor(public http: HttpClient, public route: Router) {
    this.urlTester();
    this.srollBottomtoTop();
  }

  urlTester() {
    if (window.location.hostname == 'localhost') {
      // this.baseurl = 'http://localhost:3000/api/';
      this.baseurl = environment.baseurl;
    } else {
      this.baseurl = environment.baseurl;
    }
  }
  // ------------------
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    //https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_scroll_to_top  for top
  }
  srollBottomtoTop() {
    this.route.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    // https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change // another option in routimg.modules.ts file in imports
  }
  // -------------------------
  // for Post data
  postApi(url, data): Observable<any> {
    return this.http.post(this.baseurl + url, data)
  }
  // for get data
  getApi(url): Observable<any> {
    return this.http.get(this.baseurl + url)
  }
  // for json data
  staticjsonurl = 'assets/JsonData/';
  getjsonData(Jsonurl) {
    return this.http.get(this.staticjsonurl + Jsonurl);
  }
  //for redirect with refresh
  navigateTo(route) { //refresh
    this.route.navigate([route]);
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }
  checkSystemWidth(): string {
    var width = window.innerWidth;
    let device: string;
    if (width <= 768) {
      device = 'sm';
    } else if (width > 768 && width <= 992) {
      device = 'md';
    } else {
      device = 'lg';
    }
    return device;
  }
  redirectTo(url: string, isNewTab: boolean = true): void {
    if (isNewTab) {
      window.open(url, "_blank");
    } else {
      // same tab
      window.location.href = url;
    }
  }
}
