import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  // { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    pathMatch: "full",
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  { path: 'live-score', loadChildren: () => import('./pages/live-score/live-score.module').then(m => m.LiveScoreModule) },
  {
    path: "header",
    loadChildren: () =>
      import("./header/header.module").then((m) => m.HeaderModule),
  },
  {
    path: "footer",
    loadChildren: () =>
      import("./footer/footer.module").then((m) => m.FooterModule),
  },
  {
    path: "vision",
    loadChildren: () =>
      import("./gt20/vision/vision.module").then((m) => m.VisionModule),
  },
  {
    path: "cricketcanada",
    loadChildren: () =>
      import("./gt20/cricketcanadan/cricketcanadan.module").then(
        (m) => m.CricketcanadanModule
      ),
  },
  // { path: 'advisorycommittee', loadChildren: () => import('./gt20/advisory-committee/advisory-committee.module').then(m => m.AdvisoryCommitteeModule) },
  {
    path: "inthenews",
    loadChildren: () =>
      import("./gt20/in-the-news/in-the-news.module").then(
        (m) => m.InTheNewsModule
      ),
  },
  {
    path: "results",
    loadChildren: () =>
      import("./matchcenter/results/results.module").then(
        (m) => m.ResultsModule
      ),
  },
  {
    path: "tournament-stats",
    loadChildren: () =>
      import("./matchcenter/tournament-stats/tournament-stats.module").then(
        (m) => m.TournamentStatsModule
      ),
  },
  {
    path: "player-stats",
    loadChildren: () =>
      import("./matchcenter/player-stats/player-stats.module").then(
        (m) => m.PlayerStatsModule
      ),
  },
  {
    path: "standings",
    loadChildren: () =>
      import("./matchcenter/standings/standings.module").then(
        (m) => m.StandingsModule
      ),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "gallery",
    loadChildren: () =>
      import("./media/gallery/gallery.module").then((m) => m.GalleryModule),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./media/news/news.module").then((m) => m.NewsModule),
  },
  {
    path: "medianews",
    loadChildren: () =>
      import("./media/medianews/medianews.module").then(
        (m) => m.MedianewsModule
      ),
  },

  {
    path: "videos",
    loadChildren: () =>
      import("./media/videos/videos.module").then((m) => m.VideosModule),
  },
  {
    path: "brampton-wolves",
    loadChildren: () =>
      import("./teams/brampton-wolves/brampton-wolves.module").then(
        (m) => m.BramptonWolvesModule
      ),
  },
  {
    path: "montreal-tigers",
    loadChildren: () =>
      import("./teams/montreal-tigers/montreal-tigers.module").then(
        (m) => m.MontrealTigersModule
      ),
  },
  // {
  //   path: "edmonton-royals",
  //   loadChildren: () =>
  //     import("./teams/edmonton-royals/edmonton-royals.module").then(
  //       (m) => m.EdmontonRoyalsModule
  //     ),
  // },
  {
    path: "toronto-nationals",
    loadChildren: () =>
      import("./teams/toronto-nationals/toronto-nationals.module").then(
        (m) => m.TorontoNationalsModule
      ),
  },
  {
    path: "vancouver-knights",
    loadChildren: () =>
      import("./teams/vancouver-knights/vancouver-knights.module").then(
        (m) => m.VancouverKnightsModule
      ),
  },
  // {
  //   path: "winnipeg-hawks",
  //   loadChildren: () =>
  //     import("./teams/winnipeg-hawks/winnipeg-hawks.module").then(
  //       (m) => m.WinnipegHawksModule
  //     ),
  // },
  { path: 'surrey-jaguars', loadChildren: () => import('./teams/surrey-jaguars/surrey-jaguars.module').then(m => m.SurreyJaguarsModule) },
  { path: 'mississauga-panthers', loadChildren: () => import('./teams/mississauga-panthers/mississauga-panthers.module').then(m => m.MississaugaPanthersModule) },
  { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
  {
    path: "trending",
    loadChildren: () =>
      import("./pages/trending/trending.module").then((m) => m.TrendingModule),
  },
  // {
  //   path: "buytickets",
  //   loadChildren: () =>
  //     import("./pages/buytickets/buytickets.module").then(
  //       (m) => m.BuyticketsModule
  //     ),
  // },
  // {
  //   path: "tickets",
  //   loadChildren: () =>
  //     import("./pages/buytickets/buytickets.module").then(
  //       (m) => m.BuyticketsModule
  //     ),
  // },
  { path: 'anti-racism', loadChildren: () => import('./pages/anti-racism/anti-racism.module').then(m => m.AntiRacismModule) },
  { path: 'ticket-terms-conditions', loadChildren: () => import('./pages/ticket-terms-conditions/ticket-terms-conditions.module').then(m => m.TicketTermsConditionsModule) },
  { path: 'auction', loadChildren: () => import('./pages/charity-bid/charity-bid.module').then(m => m.CharityBidModule) },
  {
    path: "fixture",
    loadChildren: () =>
      import("./pages/fixture/fixture.module").then((m) => m.FixtureModule),
  },
  {
    path: "shopping",
    loadChildren: () =>
      import("./pages/shop/shop.module").then((m) => m.ShopModule),
  },
  {
    path: "career",
    loadChildren: () =>
      import("./pages/career/career.module").then((m) => m.CareerModule),
  },

  // {
  //   path: "pagenotfound",
  //   loadChildren: () =>
  //     import("./PageNotFound/pagenotfound.module").then(
  //       (m) => m.PagenotfoundModule
  //     ),
  // },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled", // Add options right here  https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
