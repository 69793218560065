import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('toggleButton', { static: true }) toggleButton: ElementRef;
  @ViewChild('menu', { static: true }) menu: ElementRef;
  isLoginActive: boolean = false; //===directly true false from here

  constructor(private renderer: Renderer2, private route: Router) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.toggleButton.nativeElement.contains(e.target)) {
      }
    });
  }

  isMenuOpen: boolean = false;
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  first: any = 'mm-panel mm-hasnavbar mm-opened mm-current';
  another: any = 'mm-panel mm-hasnavbar mm-hidden';
  another_two: any = 'mm-panel mm-hasnavbar mm-hidden';
  another_three: any = 'mm-panel mm-hasnavbar mm-hidden';
  another_four: any = 'mm-panel mm-hasnavbar mm-hidden';
  openTab(event) {
    this.first = 'mm-panel mm-hasnavbar mm-opened mm-subopened ';
    if (event === 'GT20') {
      this.another = 'mm-panel mm-hasnavbar mm-highest mm-current mm-opened';
    }
    if (event === 'Teams') {
      this.another_two = 'mm-panel mm-hasnavbar mm-highest mm-current mm-opened';
    }
    if (event === 'Match Centre') {
      this.another_three = 'mm-panel mm-hasnavbar mm-highest mm-current mm-opened';
    }
    if (event === 'Media') {
      this.another_four = 'mm-panel mm-hasnavbar mm-highest mm-current mm-opened';
    }
  }
  closeTab(event) {
    this.first = 'mm-panel mm-hasnavbar mm-opened mm-current';
    if (event === 'GT20') {
      this.another = 'mm-panel mm-hasnavbar mm-hidden';
    }
    if (event === 'Teams') {
      this.another_two = 'mm-panel mm-hasnavbar mm-highest';
    }
    if (event === 'Match Centre') {
      this.another_three = 'mm-panel mm-hasnavbar mm-hidden';
    }
    if (event === 'Media') {
      this.another_four = 'mm-panel mm-hasnavbar mm-highest';
    }
  }

  goTopage(route) { //withoutrefresh
    this.route.navigate([route]);
    this.isMenuOpen = false;
  }
}
