import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if (window && window.location.href.includes('gt20.ca')) {
  window.console.log = function () { };
  window.console.error = function () { };
  window.console.warn = function () { };
}
// https://www.codementor.io/@brijmcq/angular-clear-all-of-your-console-logs-in-production-build-with-just-a-few-lines-of-code-cxcw30yqs
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
